.hero {
  background: $primary-color;
  width: 100%;
  height: 90vh;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
  cursor: grab;
}

.hero:active {
  cursor: grabbing;
}

.hero__wrapper__cta {
  z-index: 97;
  position: absolute;
  bottom: 10%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    box-shadow: 0 0 4px rgba($primary-color, 0.2);
  }

  @include tablet {
    flex-direction: row;
    gap: 30px;
  }
}

.cta__hero {
  bottom: 30%;
  right: 30%;
  transition: all 0.5s ease-out;
  will-change: all;

  // @include mobile{
  //     right: 50%;
  //     transform: translateX(50%);
  // }
}

.cta--scrolled {
  transform: translateX(0%);
  outline: 0;
  width: 60px;
  height: 60px;
  background: url("../../static/images/email.svg"), $primary-color;
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 3%;
  right: 3%;
  z-index: 100;
  font-size: 0;

  &:hover,
  &:focus {
    outline: 0;
    background: url("../../static/images/email.svg"), $highlight-color;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.hero__canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.hero__loading-bar {
  transition: all 0.5s;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 4px;
  background: white;
  transform-origin: top left;
  transform: scaleX(0);
}

.hero__loading-numbers {
  font-size: 100px;
  transition: all 0.5s;
  position: absolute;
  top: 30%;
  color: white;
}
