.work__section {
  background: rgba($highlight-color2, 0.8);
}

.work__item {
  width: 100%;
  height: 250px;
  margin: 30px 0;
  background: $highlight-color2;
  overflow: hidden;
  background-position: center;
  background-size: cover;

  &:hover,
  &:focus-within {
    .work__h2,
    .work__text,
    .work__cta {
      transform: translate3d(0, 0, 0);
    }
    .work__item__inner {
      background: rgba($highlight-color2, 0.3);
      backdrop-filter: blur(5px);
    }
  }
}

.work__item1 {
  background-image: url("../../static/images/work1.jpg");
}

.work__item2 {
  background-image: url("../../static/images/work2.jpg");
}

.work__item__inner {
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s ease-out;
}

.work__h2,
.work__text {
  color: white;
  will-change: transform;
  transition: all 0.5s ease-out;
  transform: translate3d(-100vw, 0, 0);
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.425);
}

.work__h2 {
  color: $highlight-color;
}

.work__cta {
  display: inline-block;
  transition: all 0.5s ease-out;
  margin-top: 20px;
  text-decoration: none;
  color: $primary-color;
  background-color: $highlight-color;
  transform: translate3d(200vw, 0, 0);
  border: 0;
  font-family: inherit;
  height: 40px;
  cursor: pointer;
}

//Modal

.work__modal__wrapper {
  position: fixed;
  visibility: hidden;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  top: 0;
  left: 0;
  z-index: 98;
}

.work__modal {
  opacity: 0;
  position: absolute;
  width: 80vw;
  height: 80vh;
  background: white;
  overflow-y: scroll;
  transition: all 0.3s ease-in;
  border-radius: 5px;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */

  @include big-desktop {
    width: 50vw;
  }
}

.work__modal::-webkit-scrollbar {
  display: none;
}
.work__modal__active {
  z-index: 10;
  opacity: 1;
}

.work__modal__close {
  position: absolute;
  right: 0;
  margin: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 10;

  &::after,
  &::before {
    position: absolute;
    content: "";
    left: 15px;
    width: 2px;
    height: 25px;
    transition: all 0.3s ease-in;
    background: $primary-color;
  }

  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }

  &:hover::after,
  &:hover::before {
    background: $gradient;
    transform: rotate(90deg);
  }
}

.work__modal__inner {
  position: relative;
  flex-wrap: wrap;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.work__modal__slider {
  width: 100%;
  height: 600px;
  overflow: hidden;
  // margin-bottom: 20px;
  margin-top: -60px;
}

.work__modal__h2 {
  position: absolute;
  top: 40%;
  font-size: 35px;
  color: white;
  white-space: nowrap;
  mix-blend-mode: difference;

  @include tablet {
    font-size: 40px;
  }
}

.work__modal__text {
  margin: 0 20px;
  margin-bottom: 30px;
}

.work__modal__cta {
  margin: 10px;

  @include tablet {
    margin: 20px;
  }
}

.spacer {
  width: 100%;
  height: 20px;

  @include tablet {
    height: 30px;
  }
}

.modal__background {
  background: rgba($primary-color, 0.5);
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
}

//Icons

.work__icons {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet {
    flex-direction: row;
  }

  @include desktop {
    margin-top: 70px;
  }
}

.icon__container {
  margin: 30px;
  text-align: center;
}

.icon__img {
  overflow: hidden;
  width: 50%;

  @include mobile {
    width: 30%;
  }

  @include tablet {
    width: 70%;
  }

  @include desktop {
    width: 60%;
  }
}

.desktop {
  opacity: 0;
}

.icon__h3 {
  color: white;
  margin: 15px 0;
}
