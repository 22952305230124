$primary-color: #141414;
$highlight-color: #ffb0c8;
$highlight-color2: #3a75f0;

$gradient: linear-gradient(135deg, #ffb0c8 0%, #3a75f0 100%);

@font-face {
  font-family: "Dystopia";
  src: url("../../static/fonts/ArtDystopia.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../static/fonts/Montserrat-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "TitilliumWeb";
  src: url("../../static/fonts/TitilliumWeb-Light.ttf");
  font-display: swap;
}

@mixin mobile {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 968px) {
    @content;
  }
}

@mixin big-desktop {
  @media (min-width: 1600px) {
    @content;
  }
}
