@import "_config.scss";
@import "_nav.scss";
@import "_hero.scss";
@import "_about.scss";
@import "_work.scss";
@import "_contact.scss";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $gradient;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: $highlight-color2;
}

html {
  scroll-behavior: smooth;
  scrollbar-color: $highlight-color transparent;
  scrollbar-width: thin;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Montserrat;
  font-style: normal;
  overflow: overlay;
}

main {
  visibility: visible;
}

section {
  overflow: hidden;
  padding: 60px 0;
  margin-bottom: 50px;

  @include desktop {
    padding: 100px 0;
  }
}

h1,
h2,
h3 {
  color: $highlight-color;
  font-weight: 800;
  padding: 0;
  margin: 0;
}

h1 {
  font-weight: 100;
  font-size: 4em;
  text-align: center;
  margin-bottom: 30px;
  font-family: Dystopia;

  @include tablet {
    font-size: 5em;
  }

  @include desktop {
    margin-bottom: 60px;
    font-size: 6em;
  }
}

p {
  color: $primary-color;
  font-family: TitilliumWeb;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.8;
  margin: 0;
}

.wrapper {
  margin: 0 40px;

  @include desktop {
    margin: 0 100px;
  }
  @include big-desktop {
    margin: 0 400px;
  }
}

.flex {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @include tablet {
    flex-direction: row;
    gap: 5%;
  }
}

.all__cta {
  transition: all 0.3s ease-in;
  text-align: center;
  width: 200px;
  line-height: 40px;
  font-size: 16px;
  font-weight: normal;
  border-radius: 24px;
  text-decoration: none;
  color: $primary-color;
  background: $highlight-color;
  height: 40px;

  &:hover,
  &:focus {
    outline: 0;
    background: $gradient;
    color: white;
  }
}

footer {
  position: relative;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: $gradient;
}

.footer__link {
  text-decoration: none;
  color: $primary-color;
}
