.about__wrapper {
  margin: 80px 0;
}

.about__img__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;

  @include tablet {
    width: 50%;
  }
}

.img__canvas {
  width: 100%;
  height: 400px;
  border-radius: 6px;
}

.about__text {
  text-align: center;
  margin-top: 30px;

  @include tablet {
    width: 50%;
    margin-top: 0;
  }
}

.about__life h2 {
  margin-bottom: 20px;
}

.life__wrapper {
  display: grid;
  gap: 1em;
  grid-auto-flow: row;
  @include desktop {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
}

.life__container {
  background: rgba($highlight-color, 0.8);
  border-radius: 6px;
  padding: 10px;
  height: 70px;
  transition: all 0.5s ease-in;

  &:nth-child(1) {
    background: rgba($highlight-color2, 1);

    @include desktop {
      width: 80%;
      grid-row: 1;
      grid-column: 1 / 3;
    }
  }

  &:nth-child(2) {
    background: $gradient;

    @include desktop {
      width: 80%;
      grid-row: 2;
      grid-column: 2 / 4;
    }
  }

  &:nth-child(3) {
    @include desktop {
      grid-row: 3;
      grid-column: 3 / 5;
    }
  }

  &:nth-child(4) {
    @include desktop {
      width: 90%;
      justify-self: end;
      grid-row: 4;
      grid-column: 4 / 6;
    }
  }

  &:nth-child(5) {
    background: $gradient;
    @include desktop {
      width: 70%;
      justify-self: end;
      grid-row: 5;
      grid-column: 4 / 6;
    }
  }

  h3 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: $primary-color;

    span {
      color: white;
    }
  }

  ul {
    font-family: TitilliumWeb;
    list-style-type: "\1F40B";
  }

  p {
    color: $primary-color;
  }

  &:hover {
    .life__modal {
      visibility: visible;
      opacity: 1;
    }
  }
}

.life__modal {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  background: white;
  width: 80vw;
  padding: 20px;
  transition: all 0.5s ease-in;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba($primary-color, 0.4);

  @include desktop {
    width: 50vw;
    top: -10%;
  }
}

.life__modal__active {
  opacity: 1;
}
