nav {
  display: flex;
  position: fixed;
  width: 100%;
  height: 50px;
  z-index: 99;
  background-color: $highlight-color;
}

.logo {
  position: absolute;
  margin-left: 15px;
  margin-top: 5px;
  z-index: 3;
  width: 30px;
  height: 40px;
  background-image: url("../../static/images/icon.svg");
  background-size: auto;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  font-size: 0;

  &:hover,
  &:focus {
    transform: rotate(123deg);
  }
}

.nav__burger {
  position: absolute;
  right: 0;
  top: 0;
  margin: 15px;
  z-index: 3;

  transition: all 0.5s ease-out;

  .burger__line {
    width: 40px;
    height: 3px;
    margin: 0 0 5px 0;
    background-color: $highlight-color2;
    box-shadow: 1px 1px 0px $primary-color;
    transition: all 0.5s ease-out;
  }

  @include tablet {
    display: none;
  }
}

.nav--close {
  transform: rotate(0.5turn);
  top: 10px;

  .burger__line {
    margin: 0;
    background-color: $primary-color;

    &:nth-child(1) {
      transform: translateZ(0) rotate(-315deg);
    }

    &:nth-child(2) {
      transform: translateZ(0) translateY(-60%) rotate(-225deg);
    }

    &:nth-child(3) {
      opacity: 0;
    }
  }
}

.nav__link {
  text-decoration: none;
  color: $primary-color;
  margin: 20px 0;
  display: inline-block;

  &:focus {
    outline: 0;
  }

  &::after {
    content: "";
    display: block;
    width: 0%;
    height: 3px;
    background-color: $primary-color;
    transition: all 0.3s ease-out;
  }

  &:hover::after,
  &:focus::after {
    width: 100%;
  }

  @include tablet {
    margin: 0 30px;
  }
}

.nav__inner {
  display: inline-flex;
  overflow: hidden;
  width: 100%;
  height: 0;
  z-index: 2;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 30px;
  background: $highlight-color;
  transition: all 0.3s ease-in-out;

  @include tablet {
    justify-content: flex-end;
    height: auto;
    flex-direction: row;
    font-size: 1rem;
  }
}

.nav__inner--close {
  height: 100vh;

  @include tablet {
    height: 50px;
  }
}

.nav__line {
  height: 1px;
  width: 80%;
  background-color: $primary-color;

  @include tablet {
    width: 0px;
  }
}
