.contact__container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.contact__text {
  text-align: center;

  @include desktop {
    width: 70%;
  }
}

.contact__cta {
  transition: all 0.5s ease-out;
  margin-top: 80px;
  text-decoration: none;
  color: $primary-color;
  background-color: $highlight-color;
}
